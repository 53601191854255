import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Helmet from 'react-helmet';
import PricingScreen from '@fathomtech/nualang-ui-components/dist/Screens/Pricing';
import Layout from '../../../layouts/Default';
import plans from '../../../plans/index';
import i18n from '../../../../i18n';
import testimonials from '../../../testimonials';
import config from '../../../config';

function Pricing(props) {
  const {
    data: {
      site: {
        siteMetadata: { title },
      },
    },
  } = props;
  return (
    <>
      <Helmet title={`Pricing Plans | ${title}`} />
      <PricingScreen
        {...props}
      />
    </>
  );
}

Pricing.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
};

const currencies = [
  {
    value: 'usd',
    label: '$ USD',
  },
  {
    value: 'eur',
    label: '€ EUR',
  },
  {
    value: 'gbp',
    label: '£ GBP',
  },
  {
    value: 'cad',
    label: '$ CAD',
  },
];

function PricingPage(props) {
  const {
    location, data,
  } = props;
  const qsParams = queryString.parse(location.search);
  const { t } = useTranslation();

  const handleStartFreeTrial = async () => {
    const { redirect } = qsParams;
    window.location.href = `${config.APP_URL}/start-trial${redirect ? `?redirect=${redirect}` : ''}`;
  };

  const currentLanguage = i18n.language;
  let initialCurrency = 'usd';
  if (currentLanguage === 'en-GB') {
    initialCurrency = 'gbp';
  } else if (currentLanguage === 'en') {
    initialCurrency = 'eur';
  }

  return (
    <Layout isSignedIn={true} disableNavbar>
      <Pricing
        t={t}
        plans={Object.values(plans)}
        initialCurrency={initialCurrency}
        currencies={currencies}
        data={data}
        quotes={testimonials}
        freeTrial={{
          isAvailable: false,
          onClick: handleStartFreeTrial,
          title: t('free_trial_title'),
          description: t('free_trial_description'),
          buttonText: t('free_trial_button'),
        }}
      />
    </Layout>
  );
}

PricingPage.propTypes = {
  location: PropTypes.object,
  data: PropTypes.shape({
    site: PropTypes.object,
  }),
};

export default PricingPage;

export const pricingPageQuery = graphql`
  query PricingUpgradeQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
